<template>
  <div class="PlaneLibrary">
    <AssetsBin
      @category="getListData"
      @select="handleSelect"
      @emptyList="handleEmptyList"
    />
    <AssetsList
      @select="handleSelect"
      @search="handleSearch"
      @handleAllChecked="handleAllChecked"
      :checkedArr="checkedArr"
      :paging="paging"
      :select="select"
      ref="msg"
    />
    <AddPlane @refresh="handleRefresh" />
    <!-- 列表数据 -->
    <div class="PlaneLibrary-box" v-loading="loading">
      <div
        class="PlaneLibrary-item"
        v-for="(item, index) in listData"
        :key="index"
        @mouseenter="mouseenter(item)"
        @mouseleave="mouseleave(item)"
      >
        <!-- 边框 -->
        <div class="PlaneLibrary-content">
          <div class="PlaneLibrary-content-top">
            <div
              class="item-width"
              v-for="(color, index) in item.frameFillColor"
              :key="'1_' + index"
              :style="{ backgroundColor: color }"
            ></div>
          </div>

          <div class="PlaneLibrary-content-box">
            <div class="PlaneLibrary-content-left">
              <div
                v-show="index > 4"
                class="item-height"
                v-for="(color, index) in item.frameFillColor"
                :key="'1_' + index"
                :style="{ backgroundColor: color }"
              ></div>
            </div>
            <!-- 填充内容 -->
            <div>
              <div
                class="PlaneLibrary-content-color"
                :style="{ background: item.fillColor }"
              >
                <!-- 按钮 -->
                <div class="PlaneLibrary-content-color-icon">
                  <!-- 收藏按钮 -->
                  <list-tooltip content="取消收藏">
                    <i
                      class="icon icon-list-collect1"
                      @click="handleCollect(item)"
                      v-show="
                        (!select && item.collection === 1) ||
                        search.typeNum == '10'
                      "
                    ></i>
                  </list-tooltip>
                  <el-dropdown
                    trigger="click"
                    ref="dropdown"
                    @visible-change="val => dropdownChange(val, '10', index)"
                    :hide-on-click="false"
                  >
                    <list-tooltip content="收藏">
                      <i
                        class="icon icon-list-collect"
                        @click="handleCollect(item)"
                        v-show="!select && item.mouse && item.collection === 0"
                      ></i>
                    </list-tooltip>
                    <el-dropdown-menu slot="dropdown">
                      <FileDialog
                        @dropdownClose="dropdownClose(index)"
                        @handleCheck="id => handleCheck(id, item, index)"
                        ref="FileDialog"
                      />
                    </el-dropdown-menu>
                  </el-dropdown>

                  <!-- 编辑按钮 -->
                  <list-tooltip content="编辑">
                    <i
                      class="icon icon-list-edit"
                      @click="handleEdit(item)"
                      v-show="
                        !select &&
                        item.mouse &&
                        search.system != 1 &&
                        search.typeNum == '9'
                      "
                    ></i>
                  </list-tooltip>

                  <!-- 选择状态按钮 -->
                  <i
                    :class="
                      item.checked
                        ? 'icon icon-list-checked1'
                        : 'icon icon-list-checked'
                    "
                    v-if="select"
                    @click="handleChecked(item)"
                  ></i>
                </div>
              </div>
            </div>
            <div class="PlaneLibrary-content-right">
              <div
                v-show="index < 5"
                class="item-height"
                v-for="(color, index) in item.frameFillColor"
                :key="'1_' + index"
                :style="{ backgroundColor: color }"
              ></div>
            </div>
          </div>
          <div class="PlaneLibrary-content-bottom">
            <div
              class="item-width"
              v-show="index > 4"
              v-for="(color, index) in item.frameFillColor"
              :key="'1_' + index"
              :style="{ backgroundColor: color }"
            ></div>
            <div
              class="item-width"
              v-show="index < 5"
              v-for="(color, index) in item.frameFillColor"
              :key="'2_' + index"
              :style="{ backgroundColor: color }"
            ></div>
          </div>
        </div>
        <el-tooltip
          :disabled="item.name.length < 9"
          :content="item.name"
          placement="right"
          effect="light"
        >
          <p class="PlaneLibrary-content-name">{{ item.name }}</p>
        </el-tooltip>
      </div>
      <div v-if="!listData[0] && !loading" class="PlaneLibrary-emptyBox">
        <div v-if="emptyStatus == 'emptyData'">
          <img src="~a/workbench/materiaLibrary/emptyData.png" />
          <p class="emptyText">暂无素材</p>
        </div>

        <div v-else-if="emptyStatus == 'emptySearch'">
          <img src="~a/workbench/materiaLibrary/emptySearch.png" />
          <p class="emptyText">抱歉，没有发现相关的内容</p>
          <p class="emptyTextSearch">换个关键词试试吧</p>
        </div>

        <div v-else-if="emptyStatus == 'emptyNetwork'">
          <img src="~a/workbench/materiaLibrary/emptyNetwork.png" />
          <p class="emptyText">抱歉，网络请求异常</p>
          <p class="emptyTextSearch">刷新页面试试吧</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileDialog from 'c/basic/FileDialog.vue';
import AddPlane from './AddPlane';
import AssetsBin from './AssetsBin.vue';
import AssetsList from './AssetsList.vue';
import ListTooltip from 'c/basic/ListTooltip.vue';
export default {
  name: 'PlaneLibrary',
  components: {
    AddPlane,
    AssetsBin,
    AssetsList,
    FileDialog,
    ListTooltip
  },
  data() {
    return {
      // 选择
      select: false,
      // 选中状态
      checked: false,
      // 选择中的数组
      checkedArr: [],
      // 鼠标经过显示
      mouse: false,
      listData: [],
      search: {
        categoryId: '', // 分类id，默认查所有
        system: '0', // 当前tab 1为系统 0为其他
        keywords: '', // 搜索关键字
        typeNum: ''
      },
      // 分页数据
      paging: {
        size: 16,
        page: 1,
        total: 0
      },
      // 状态显示，emptyData为查询数据为空状态，emptySearch为搜索数据为空状态， emptyNetwork为网络请求失败状态
      emptyStatus: '',
      // 加载动画
      loading: false
    };
  },
  computed: {
    styleCurrentPaged: {
      get() {
        return this.$store.state.materialLibrary.styleCurrentPaged;
      }
    }
  },
  methods: {
    dropdownChange(val, text, index) {
      if (val) {
        this.$refs.FileDialog[index].getFileData(text);
      } else {
        this.$refs.FileDialog[index].refreshClassify(1);
      }
    },
    // 搜索
    handleSearch(val) {
      this.search.keywords = val;
      this.getListData();
    },
    // 刷新列表数据
    handleRefresh() {
      this.getListData();
    },
    // 清空列表数据
    handleEmptyList() {
      this.listData = [];
    },
    // 获取分页数据
    handleCurrentChange(val) {
      this.paging.page = val;
      this.getListData();
    },
    // 获取数据
    getListData(data) {
      if (data) {
        this.search = data;
      }

      this.loading = true;
      const params = {
        categoryId: this.search.categoryId,
        system: this.search.system,
        keywords: this.search.keywords,
        page: this.styleCurrentPaged,
        selectCollection: this.search.system
      };
      for (var key in params) {
        params[key] === '' && delete params[key];
      }
      // 判断是否为搜索状态，用于空状态的显示效果
      this.emptyStatus = params.keywords ? 'emptySearch' : 'emptyData';
      let type =
        this.search.typeNum == '10'
          ? 'stylePolygonCollectionList'
          : 'stylePolygonList';
      this.$service.materialLibrary[type](params)
        .then(res => {
          if (res.data.status === 200) {
            if (res.data.totalPage < res.data.page && res.data.totalPage != 0) {
              this.$store.commit('materialLibrary/setStyleCurrentPaged', 1);
              this.getListData();
            }
            this.paging = {
              size: 15,
              page: res.data.page,
              total: res.data.total
            };
            this.listData = res.data.data;
            res.data.data.map((item, index) => {
              const frameFillColor = [];
              var num = 0;
              for (let i = 0; i < item.frameFill.length; i++) {
                const element = item.frameFill.charAt(i);
                if (element == 1) {
                  frameFillColor.push(item.frameFillColor[num]);
                  num = num + 1;
                } else {
                  frameFillColor.push('#FFFFFF00');
                }
              }
              this.listData[index].frameFillColor = frameFillColor;
            });
          }
          this.loading = false;
        })
        .catch(() => {
          this.emptyStatus = 'emptyNetwork';
          this.loading = false;
        });
    },

    // 编辑
    handleEdit(val) {
      const data = {
        title: '编辑多边形样式',
        status: true,
        data: val
      };
      this.$store.commit('materialLibrary/setAddPlaneDialogVisible', data);
    },
    // 收藏
    handleCheck(id, item, index) {
      const params = {
        id: item.id,
        categoryId: id
      };
      this.$service.materialLibrary.stylePolygonCollection(params).then(res => {
        if (res.data.status === 200) {
          this.$refs.dropdown[index].visible = false;
          this.getListData();
          this.$refs.msg.handleMessageBox('收藏成功');
        }
      });
    },
    // 取消收藏
    handleCollect(data) {
      if (data.collection || this.search.typeNum == 10) {
        let params = new FormData();
        params.append('polygonId', data.id);
        this.$service.materialLibrary
          .stylePolygonCollectionDelBatch(params)
          .then(res => {
            if (res.data.status === 200) {
              this.getListData();
              this.$refs.msg.handleMessageBox('取消收藏');
            }
          });
      }
    },
    // 选择
    handleSelect(val) {
      this.select = val;
      this.checkedArr = val === false ? '' : this.checkedArr;
    },
    // 点击取消去除方法
    removeItem(arr, item) {
      let targetIndex = arr.findIndex(itemTemp => itemTemp === item);
      if (targetIndex !== -1) {
        arr.splice(targetIndex, 1);
      }
    },
    // 选中与取消
    handleChecked(item) {
      if (!item.checked) {
        item.checked = true;
        this.checkedArr.push(item.id);
      } else {
        item.checked = false;
        this.$refs.msg.handleSelectText('全选');
        this.removeItem(this.checkedArr, item.id);
      }
    },
    // 全选
    handleAllChecked(val) {
      if (val === false) {
        for (let i = 0; i < this.listData.length; i++) {
          this.listData[i].checked = val;
        }
        this.checkedArr = [];
      } else {
        this.checkedArr = [];
        for (let i = 0; i < this.listData.length; i++) {
          if (!this.listData[i].checked) {
            for (let i = 0; i < this.listData.length; i++) {
              this.listData[i].checked = true;
              this.checkedArr.push(this.listData[i].id);
            }
            break;
          } else {
            this.listData[i].checked = false;
          }
        }
      }
      if (
        this.listData.length === this.checkedArr.length &&
        this.listData.length !== 0
      ) {
        this.$refs.msg.handleSelectText('取消全选');
      } else {
        this.$refs.msg.handleSelectText('全选');
      }
    },
    mouseenter(item) {
      this.$set(item, 'mouse', true);
    },
    mouseleave(item) {
      this.$set(item, 'mouse', false);
    },
    dropdownClose(index) {
      this.$refs.dropdown[index].visible = false;
    }
  }
};
</script>
<style lang="less" scoped>
.PlaneLibrary {
  &-box {
    height: calc(100vh - 641px);
    overflow: hidden;
    overflow-y: auto;
  }
  &-item {
    display: inline-block;
    margin: 0 0px 4px 20px;
  }
  &-content {
    width: 125px;
    height: 60px;
    overflow: hidden;
    background-image: url(~a/workbench/materiaLibrary/block1-bg.png);
    .item-width {
      display: flex;
      width: 12.5px;
      height: 5px;
    }
    .item-height {
      display: flex;
      width: 5px;
      height: 11px;
    }
    &-top {
      display: flex;
    }
    &-right {
      display: flex;

      flex-direction: column;
    }
    &-bottom {
      display: flex;
      flex-direction: row-reverse;
    }
    &-left {
      display: flex;
      flex-direction: column-reverse;
    }
    &-box {
      height: 50px;
      display: flex;
      justify-content: space-between;
    }
    &-color {
      width: 115px;
      height: 50px;
      z-index: 0;
      // 图标按钮
      &-icon {
        padding: 2px 2px 0 0;
        float: right;
        height: 20px;
        align-items: center;
        icon-list-edit {
          margin-right: 16px;
        }
        .icon {
          cursor: pointer;
          margin-left: 6px;
          z-index: 1;
        }
      }
    }

    &-name {
      width: 135px;
      text-align: center;
      font-size: 14px;
      color: #666666;
      margin-top: 2px;
      text-overflow: ellipsis; /*显示省略符号来代表被修剪的文本*/
      overflow: hidden;
      white-space: nowrap; /*不换行 控制单行文本*/
    }
  }
  // 空状态
  &-emptyBox {
    height: calc(100vh - 641px);
    overflow: hidden;
    overflow-y: auto;
    img {
      display: flex;
      margin: 40px auto 0;
      width: 212px;
      height: 130px;
    }
    .emptyText {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
      line-height: 16px;
      font-size: 16px;
      text-align: center;
      margin: 40px auto 10px;
    }
    .emptyTextSearch {
      text-align: center;
      font-size: 13px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #1b1b1b;
      line-height: 16px;
      opacity: 0.5;
    }
  }
}
.el-dropdown-menu {
  background: none;
  text-align: justify;
  border: none;
  box-shadow: none;
  border-radius: none;
}
</style>
